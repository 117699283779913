import axios from "axios";

import authHeader from "./auth-header";

const API_URL =
  "https://us-central1-pamo-digital-376fe.cloudfunctions.net/api/";
// for testing my backend express api
// const API_URL = "http://localhost:5001/pamo-digital-376fe/us-central1/api/";

class UserService {
  getPublicContent() {
    return axios.get(API_URL + "all");
  }

  getUser(uid) {
    // return axios.get(API_URL + "user", {
    //   headers: authHeader(),
    // });
    return axios.get(API_URL + "users/" + uid, {
      headers: authHeader(),
    });
  }

  editUser(userDetails, userId) {
    return axios.patch(API_URL + "users/" + userId, userDetails, {
      headers: authHeader(),
    });
  }

  createUser(userDetails) {
    console.log("object to submit", userDetails);
    return axios.post(API_URL + "users", userDetails, {
      headers: authHeader(),
    });
  }

  getAllUsers() {
    return axios.get(API_URL + "users", { headers: authHeader() });
  }

  deleteUser(uid) {
    return axios.delete(API_URL + "users/" + uid, { headers: authHeader() });
  }
}

export default new UserService();
